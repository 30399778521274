// Paliq-colors
$global-radius: 0.125rem;
$white: #fff;
$black: #000;
$medium-gray: #e4e4e4;
$light-border: #ececec;
$global-bg: #efeff5;
$offwhite: #fffefe;
$darkgray-pq: #030303;
$graymedium-pq: #535353;
$graylight-pq: #c4c4c4;
$bluefill-pq: #28aae1;
$globalteal-pq: #0b8c5d;
$lightaccent-pq: #8dd2cd;
$tableheader-pq:  2A2A2D; //#e4e4e4;//rgba(20, 166, 71, 0.1); //#009879;
$lightshade-pq: #daf4f1;
$bluebordr-pq: #4f91ff;
$newblue-pq:#80ddf2;
$red-pq: #ea4335;
$orange-pq: #f90;
$social-brand-block: #fffefe;
$social-brand-facebook: #3b5998;
$social-brand-twitter: #55acee;
$social-brand-linkedin: #007bb5;
$social-brand-youtube: #b00;
$social-brand-instagram: #125688;
$social-brand-pinterest: #cb2027;
$social-brand-google-plus: #dd4b39;
$social-brand-github: #000;
$social-brand-tumblr: #32506d;
$input-border: #ededed; //TODO - RESTART YARN ANS SEE THE EFFECT TOMORROW MORNING
$secondary-text: #b5b5b5;
$primary-color: #009879;
// Paliq-Font family
$roboto-slab: "Roboto", sans-serif;
$table_hyperlink:royalblue;
$pq_light_green : #ebf6ed;
$pq_dark_neon_blue : #80ddf2;
$pq_light_neon_blue : #c1e9f2;
$pq_website_green : #0b8c5d;
$hyperlink_blue : #0000FF;
$tableheader_text_color : #6F7378;
// Paliq-Font Weight
$font-weight-normal: normal;
$font-weight-bold: 700;
$font-weight-300: 300;
$font-weight-500: 500;

// Paliq-Font Size
$font-size-h1: 6rem;
$font-size-h2: 3.75rem;
$font-size-h3: 3rem;
$font-size-h4: 2.125rem;
$font-size-h5: 1.5rem;
$font-size-h6: 1.25rem;
$font-size-body1: 1rem;
$font-size-body2: 0.875rem;
$font-size-subtitle1: 1rem;
$font-size-subtitle2: 0.875rem;
$font-size-caption: 0.75rem;
$font-size-overline: 0.75rem;
$font-size-button: 0.875rem;

// Menu bar
$foundation-top-bar-bg: $white;
$foundation-top-bar-text-color: $darkgray-pq;

$login-box-radius: $global-radius;
$login-box-bg: $white;
$login-box-shadow: 0 0.125rem 0.125rem rgba($graymedium-pq, 0.8);
$login-box-inner-padding: 2.5rem;
//$login-box-social-bg: url('');

// Dashboard content
$app-dashboard-top-nav-color: $globalteal-pq;
$app-dashboard-top-nav-height: auto;

// Responsive table
$responsive-card-table-bottom-gap: 1.5rem;
$responsive-card-table-border: 0.063rem solid $black;
$responsive-card-table-stack-point: 640px;
$responsive-card-table-label-bg: $graylight-pq;

// Menu bar
$foundation-top-bar-bg: $white;
$foundation-top-bar-text-color: $darkgray-pq;
