@import "../../../assets/scss/vars";

.custom-card .ant-card {
  margin: 5px;
  borderradius: 10px;
  borderwidth: 2px;
  minheight: 90vh;
  boxshadow: #009879;
  overflow: hidden;
}

.custom-card > .ant-card {
  margin: 5px;
  borderradius: 10px;
  borderwidth: 2px;
  minheight: 90vh;
  boxshadow: #009879;
  overflow: hidden;
}
