@import "../../assets/scss/vars";

.ant-menu.paliq-top-left-menu {
  position: absolute;
  left: 200px;
  width: 100%;
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px;
  background-color: $lightshade-pq;
  color: $darkgray-pq;

  .ant-menu-item-active {
    span {
      div {
        color: #0b8c5d;
      }
    }
  }
  .ant-menu-item-selected {
    color: white !important;
    span {
      div {
        color: white !important;
      }
    }
  }
}

.ant-menu.paliq-top-right-menu {
  position: absolute;
  right: 0;
  width: 160px;
  height: 48px;
  font-size: 14px;
  font-weight: bold;
  background-color: $lightshade-pq;
  color: $darkgray-pq;

  .ant-menu-item-active {
    span {
      div {
        color: #0b8c5d;
      }
    }
  }

  .ant-menu-item-selected {
    color: white !important;
  }
}
