@import "../../assets/scss/vars";

.service-container{
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    width: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.ant-space .ant-space-horizontal .ant-space-align-center{
    width: 100%;
}

.ant-table-thead .ant-table-cell {
    font-weight: normal;
    // color:#6F7380;
    color: $tableheader_text_color;//#8C8C8C;
    font-size: 14px;
    //background-color: $pq_light_neon_blue; //$tableheader-pq;//whitesmoke;
    //background-color: $lightshade-pq;
}


.link-color{
    color: $hyperlink_blue;//$globalteal-pq;
    font-weight: normal;
}

.paliq-table .ant-table-cell {
    padding: 14px 16px;
}
