@import "../../assets/scss/vars";

.ant-typography {
  &.plan-title {
    color: #009879;
    display: inline;
    line-height: 2em;
    margin-top: 12px !important;
  }
}

.ant-typography {
  &.plan-modify {
    color: #00B0F0;
    display: inline;
    line-height: 2em;
    margin-top: 12px !important;
  }
}

.ant-typography {
  &.cost-text {
    color: $globalteal-pq;
    margin-top: 12px !important;
  }
}

.subTable .ant-table-thead .ant-table-cell {
  background-color: transparent;
}

.subTable .ant-table-content {
  box-shadow: 5px 8px 24px 5px rgba(41, 51, 46, 0.4);
  width: 98%;
  float: right;
}
