* {
  font-family: "Helvetica";
}
/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", "Inter",
    "Inter v", monospace;
}*/

.ant-input-number-handler-wrap {
  display: none !important;
}

.ant-layout {
  background-color: white !important;
}

/* .ant-input:hover {
  border-color: red;
}

.ant-input:focus {
  border-color: rgba(245, 9, 40, 0.2);
  box-shadow: 0 0 0 2px rgba(245, 9, 40, 0.2);
} */
