.ant-layout-sider.paliq-sidebar {
  background-color: #fff;
  position: absolute !important;
  width: 200px;
  display: flex !important;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 10px;

  .ant-menu {
    border-left: 1px solid #f0f0f0;

    .ant-menu-item {
      padding-left: 0;
    }

    .ant-menu-item::before {
      margin-right: 12px;
      content: "";
    }

    .ant-menu-item-selected::before {
      border-left: 2px solid #009879;
    }

    .ant-menu-item-selected {
      background-color: transparent !important;
      font-weight: bold;
      font-size: 15px;
    }

    .ant-menu-item-active {
      background-color: transparent !important;
      font-weight: bold;
    }
  }
}
