@import "../../../assets/scss/vars";

.primary-button.ant-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: $globalteal-pq !important;
  background: $globalteal-pq !important;
  font-weight: bolder;
  color: $white !important;
  &:hover {
    background-color: darken($globalteal-pq, 2%) !important;
  }
}

.secondary-button.ant-btn {
  display: flex;
  align-items: center;
  font-weight: bolder;
  justify-content: center;
  background-color: transparent !important;
  color: $globalteal-pq;
  border: 1px solid $globalteal-pq !important;
  color: darken($globalteal-pq, 2%) !important;
  &:hover {
    background-color: darken($globalteal-pq, 2%);
    color: $white;
    border: 1px solid $globalteal-pq;
  }
}

.main {
  border: none;
  background: $globalteal-pq;
  &:hover {
    background-color: darken($globalteal-pq, 2%);
  }
}

.singIn {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: none;
  background: $globalteal-pq;
  &:hover {
    background-color: darken($globalteal-pq, 2%);
  }
}

.google {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid $input-border;
  &:hover {
    color: darken($globalteal-pq, 2%);
    border: 1px solid $globalteal-pq;
  }
}

.fullWidth {
  width: 100%;
}
