.site-layout .site-layout-background {
  background: #fff;
}
[data-theme="dark"] .site-layout .site-layout-background {
  background: #141414;
}

.sideNav-layout {
  min-height: 100vh;
}

.sideNav-header {
  padding: 0;
}

.sideNav-footer {
  text-align: center;
}
.ant-layout .site-layout {
  background: #fff;
}

.sign-container {
  min-height: 100vh;
  display: flex;
  background-color: #f1f0ef;
  // min-width: 100vw;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.ant-layout-content {
  padding-top: 0;
}
